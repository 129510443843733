import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Inject,
  ViewEncapsulation,
} from '@angular/core';

import { MatIcon } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { TranslateModule } from '@ngx-translate/core';

import { Config } from 'src/app/core/configs/config';
import { ESvgIcons } from 'src/app/core/enums/svg-icons';

import { SafeUrlPipe } from 'src/app/shared/pipes/safe-url.pipe';
import { BreakpointService } from 'src/app/core/services/breakpoint.service';

const cloudCDNPrefix =
  'https://res.cloudinary.com/djxk74uhg/image/fetch/f_auto';
const cloudCDNSuffix = '?_a=ATFGlAA0';

const DIALOG_DATA_STEPS = [
  {
    step: 1,
    title: 'HOME.STEPS.STEP_1.TITLE',
    content: [
      {
        title: 'HOME.STEPS.STEP_1.TIP_1.TITLE',
        paragraphs: ['HOME.STEPS.STEP_1.TIP_1.DESCRIPTION_1'],
        imageUrls: [],
      },
      {
        title: 'HOME.STEPS.STEP_1.TIP_2.TITLE',
        paragraphs: ['HOME.STEPS.STEP_1.TIP_2.DESCRIPTION_1'],
        imageUrls: [],
      },
      {
        title: 'HOME.STEPS.STEP_1.TIP_3.TITLE',
        paragraphs: ['HOME.STEPS.STEP_1.TIP_3.DESCRIPTION_1'],
        imageUrls: [],
      },
    ],
  },
  {
    step: 2,
    title: 'HOME.STEPS.STEP_2.TITLE',
    content: [
      {
        title: 'HOME.STEPS.STEP_2.TIP_1.TITLE',
        paragraphs: ['HOME.STEPS.STEP_2.TIP_1.DESCRIPTION_1'],
        imageUrls: [],
      },
      {
        title: 'HOME.STEPS.STEP_2.TIP_2.TITLE',
        paragraphs: ['HOME.STEPS.STEP_2.TIP_2.DESCRIPTION_1'],
        imageUrls: [
          {
            imageUrl: `${cloudCDNPrefix}/${Config.imgCdnUrl}images/home-page/Grouped-config.png${cloudCDNSuffix}`,
            affiliateLinkUrl: '',
          },
        ],
      },
    ],
  },
  {
    step: 3,
    title: 'HOME.STEPS.STEP_3.TITLE',
    content: [
      {
        title: 'HOME.STEPS.STEP_3.TIP_1.TITLE',
        paragraphs: ['HOME.STEPS.STEP_3.TIP_1.DESCRIPTION_1'],
        imageUrls: [
          {
            imageUrl: `${cloudCDNPrefix}/${Config.imgCdnUrl}images/booking.png${cloudCDNSuffix}`,
            affiliateLinkUrl: 'https://www.booking.com/index.html?aid=7947415',
          },
        ],
      },
      {
        title: 'HOME.STEPS.STEP_3.TIP_2.TITLE',
        paragraphs: ['HOME.STEPS.STEP_3.TIP_2.DESCRIPTION_1'],
        imageUrls: [
          {
            imageUrl: `${cloudCDNPrefix}/${Config.imgCdnUrl}images/viator.png${cloudCDNSuffix}`,
            affiliateLinkUrl:
              'https://www.viator.com/?pid=P00076405&mcid=42383&medium=link&campaign=general',
          },
        ],
      },
      {
        title: 'HOME.STEPS.STEP_3.TIP_3.TITLE',
        paragraphs: ['HOME.STEPS.STEP_3.TIP_3.DESCRIPTION_1'],
        imageUrls: [
          {
            imageUrl: `${cloudCDNPrefix}/${Config.imgCdnUrl}images/home-page/Hertz1.jpg${cloudCDNSuffix}`,
            affiliateLinkUrl: '',
          },
          {
            imageUrl: `${cloudCDNPrefix}/${Config.imgCdnUrl}images/home-page/Sixt1.jpg${cloudCDNSuffix}`,
            affiliateLinkUrl: '',
          },
          {
            imageUrl: `${cloudCDNPrefix}/${Config.imgCdnUrl}images/home-page/Enterprise1.jpg${cloudCDNSuffix}`,
            affiliateLinkUrl: '',
          },
          {
            imageUrl: `${cloudCDNPrefix}/${Config.imgCdnUrl}images/home-page/Europcar1.jpg${cloudCDNSuffix}`,
            affiliateLinkUrl: '',
          },
        ],
      },
      {
        title: 'HOME.STEPS.STEP_3.TIP_4.TITLE',
        paragraphs: ['HOME.STEPS.STEP_3.TIP_4.DESCRIPTION_1'],
        imageUrls: [
          {
            imageUrl: `${cloudCDNPrefix}/${Config.imgCdnUrl}images/kiwi.png${cloudCDNSuffix}`,
            affiliateLinkUrl:
              'https://www.kiwi.com/deep?affilid=routeperfectltdrouteperfectdeeplink&currency=USD&departure=anytime&lang=en&return=anytime&returnFromDifferentAirport=false&returnToDifferentAirport=false',
          },
        ],
      },
    ],
  },
  {
    step: 4,
    title: 'HOME.STEPS.STEP_4.TITLE',
    content: [
      {
        title: 'HOME.STEPS.STEP_4.TIP_1.TITLE',
        paragraphs: ['HOME.STEPS.STEP_4.TIP_1.DESCRIPTION_1'],
        imageUrls: [],
      },
      {
        title: 'HOME.STEPS.STEP_4.TIP_2.TITLE',
        paragraphs: ['HOME.STEPS.STEP_4.TIP_2.DESCRIPTION_1'],
        imageUrls: [],
      },
      {
        title: 'HOME.STEPS.STEP_4.TIP_3.TITLE',
        paragraphs: ['HOME.STEPS.STEP_4.TIP_3.DESCRIPTION_1'],
        imageUrls: [],
      },
      {
        title: 'HOME.STEPS.STEP_4.TIP_4.TITLE',
        paragraphs: ['HOME.STEPS.STEP_4.TIP_4.DESCRIPTION_1'],
        imageUrls: [],
      },
      {
        title: 'HOME.STEPS.STEP_4.TIP_5.TITLE',
        paragraphs: ['HOME.STEPS.STEP_4.TIP_5.DESCRIPTION_1'],
        imageUrls: [
          {
            imageUrl: `${cloudCDNPrefix}/${Config.imgCdnUrl}images/voice-map.png${cloudCDNSuffix}`,
            affiliateLinkUrl: '',
          },
        ],
      },
    ],
  },
];

@Component({
  selector: 'app-step-of-the-way-dialog',
  templateUrl: './step-of-the-way-dialog.component.html',
  styleUrl: './step-of-the-way-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [MatIcon, SafeUrlPipe, TranslateModule],
})
export class StepOfTheWayDialogComponent {
  protected readonly eSvgIcons = ESvgIcons;
  protected readonly dataSteps = DIALOG_DATA_STEPS;

  protected currentStep: number;

  protected breakpointService: BreakpointService = inject(BreakpointService);
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      step: number;
    },
    protected readonly dialogRef: MatDialogRef<StepOfTheWayDialogComponent>,
  ) {
    this.currentStep = data.step;
  }

  protected setStep(step: number): void {
    this.currentStep = step;
    this.cdr.markForCheck();
  }
}
