import { NgModule } from '@angular/core';
import { Location } from '@angular/common';

import { HomeRoutingModule } from 'src/app/modules/home/home-routing.module';

import { HomeViewComponent } from 'src/app/modules/home/home-view/home-view.component';

@NgModule({
  imports: [HomeRoutingModule, HomeViewComponent],
  providers: [Location],
})
export class HomeModule {}
