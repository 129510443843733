import { Component, inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AsyncPipe } from '@angular/common';

import { MatIcon } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { TranslateModule } from '@ngx-translate/core';

import { Config } from 'src/app/core/configs/config';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { ESvgIcons } from 'src/app/core/enums/svg-icons';
import { ERoutes } from 'src/app/core/enums/routes.enum';
import { SafeUrlPipe } from 'src/app/shared/pipes/safe-url.pipe';
import { SwiperDirective } from 'src/app/shared/directives/swiper.directive';

import { CloudinaryService } from 'src/app/core/services/cloudinary.service';
import { BreakpointService } from 'src/app/core/services/breakpoint.service';
import { DomainTrackingService } from 'src/app/core/services/domain-tracking.service';
import { MetaTagsConfigService } from 'src/app/core/services/meta-tags-config.service';

import { TripWizardComponent } from 'src/app/shared/components/trip-wizard/trip-wizard.component';
import { TestimonialsComponent } from 'src/app/shared/components/testimonials/testimonials.component';
import { TopItinerariesHomepageComponent } from '../top-itineraries-homepage/top-itineraries-homepage.component';
import { StepOfTheWayDialogComponent } from '../dialogs/step-of-the-way-dialog/step-of-the-way-dialog.component';
import { PlanningFormSheetComponent } from '../../../shared/sheets/planning-form-sheet/planning-form-sheet.component';
import { StartPlaningMobileComponent } from 'src/app/shared/components/start-planing-mobile/start-planing-mobile.component';

const PARTNERS_LOGO_IMG: string[] = [
  'booking.png',
  'viator.png',
  'kiwi.png',
  'voice-map.png',
];
const CAR_RENTAL_PARTNERS_IMG: string[] = [
  'Hertz1.jpg',
  'Sixt1.jpg',
  'Enterprise1.jpg',
  'Europcar1.jpg',
];

@Component({
  selector: 'app-home-view',
  templateUrl: './home-view.component.html',
  styleUrls: ['./home-view.component.scss'],
  imports: [
    TranslateModule,
    SwiperDirective,
    MatIcon,
    AsyncPipe,
    SafeUrlPipe,
    TripWizardComponent,
    TestimonialsComponent,
    StartPlaningMobileComponent,
    TopItinerariesHomepageComponent,
  ],
})
export class HomeViewComponent implements OnDestroy {
  readonly eSvgIcons = ESvgIcons;

  public heroSlides = [
    {
      id: 1,
      title: 'Go classic',
      description:
        "Simply enter your destination/s and travel dates to generate a classic itinerary, enjoyed by thousands of travelers. Then fine-tune your itinerary until it's tailored to perfection.",
      imageUrl: `${Config.imgCdnUrl}images/home-page/main-page-slider/slide1.jpg`,
    },
    {
      id: 2,
      title: 'Optimize AI with RoutePerfect',
      description:
        'Chat with AI, describe your dream trip and your preferences. RoutePerfect’s crowdsourced database and travel expertise are available to suggest new ideas and optimize your itinerary.',
      imageUrl: `${Config.imgCdnUrl}images/home-page/main-page-slider/slide2.jpg`,
    },
    {
      id: 3,
      title: 'Choose a popular itinerary',
      description:
        "Get inspired by popular itineraries enjoyed by thousands of travelers including special themed trips - art, culinary, music, and other interests. Then fine-tune your itinerary until it's tailored to perfection.",
      imageUrl: `${Config.imgCdnUrl}images/home-page/main-page-slider/slide3.jpg`,
    },
  ];

  public heroBgUrl: string = `${Config.imgCdnUrl}images/main-bg.jpg`;
  public preferencesImgUrl: string = `${Config.imgCdnUrl}images/home-page/Grouped-config.png`;
  public partnersLogosUrl: string[] = [];
  public carRentalPartners: string[] = [];

  isWhiteLabel: boolean = false;
  swiperOptions: SwiperOptions = {
    direction: 'horizontal',
    autoplay: {
      delay: 8_000,
    },
    breakpoints: {
      375: {
        slidesPerView: 1.35,
        spaceBetween: 16,
        slidesOffsetBefore: 16,
        slidesOffsetAfter: 16,
      },
      524: {
        slidesPerView: 1.4,
        spaceBetween: 20,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
      },
      768: {
        slidesPerView: 1.5,
        spaceBetween: 24,
        slidesOffsetBefore: 24,
        slidesOffsetAfter: 24,
      },
      991: {
        slidesPerView: 3,
        spaceBetween: 32,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        loop: false,
      },
      1100: {
        slidesPerView: 1,
        spaceBetween: 0,
        slidesOffsetAfter: 0,
        slidesOffsetBefore: 0,
        loop: true,
      },
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };

  readonly #matDialog = inject(MatDialog);
  readonly #matBottomSheet = inject(MatBottomSheet);
  readonly #cloudinaryService = inject(CloudinaryService);
  readonly #breakpointService = inject(BreakpointService);

  constructor(
    public route: ActivatedRoute,
    private readonly router: Router,
    private readonly cloudinaryService: CloudinaryService,
    private readonly metaTagsConfigService: MetaTagsConfigService,
    private readonly domainTrackingService: DomainTrackingService,
  ) {
    this.setCloudinaryImages();
    this.metaTagsConfigService.setMetaTags(ERoutes.HOME_VIEW_TAG);
    this.isWhiteLabel = this.domainTrackingService.checkIfIsWhitelabel();
    if (this.isWhiteLabel) {
      const currentParams = this.route.snapshot.queryParams;
      this.router
        .navigate([ERoutes.SLASH, ERoutes.TRIP_PLANNER], {
          queryParams: currentParams,
        })
        .then();
    }
  }

  ngOnDestroy(): void {
    this.metaTagsConfigService.removeMetaTags();
  }

  getActiveIndex(activeIndex: number): string {
    if (!activeIndex) return '0';
    return activeIndex.toString();
  }

  goToPlanningForm(): void {
    this.#matBottomSheet.open(PlanningFormSheetComponent, {
      hasBackdrop: false,
      closeOnNavigation: true,
      panelClass: 'custom-bottom-sheet',
    });
  }

  showMore(e: Event, step: number): void {
    e.preventDefault();
    e.stopPropagation();

    this.#matDialog.open(StepOfTheWayDialogComponent, {
      data: { step },
      maxWidth: '724px',
      panelClass: 'step-of-the-way-mobile-container',
    });
  }

  private setCloudinaryImages(): void {
    this.heroSlides.forEach((slide) => {
      slide.imageUrl = this.#cloudinaryService.getCloudImage(
        slide.imageUrl,
        true,
      );
    });

    if (this.#breakpointService.isMobile()) {
      this.heroBgUrl = this.heroBgUrl.replace('main-bg', 'main-bg-mobile');
    }

    this.heroBgUrl = `url(${this.#cloudinaryService.getCloudImage(
      this.heroBgUrl,
      true,
    )})`;
    this.preferencesImgUrl = this.cloudinaryService.getCloudImage(
      this.preferencesImgUrl,
      true,
    );
    this.partnersLogosUrl = PARTNERS_LOGO_IMG.map((partnerPath) =>
      this.cloudinaryService.getCloudImage(
        `${Config.imgCdnUrl}images/${partnerPath}`,
      ),
    );
    this.carRentalPartners = CAR_RENTAL_PARTNERS_IMG.map((partnerPath) =>
      this.cloudinaryService.getCloudImage(
        `${Config.imgCdnUrl}images/home-page/${partnerPath}`,
      ),
    );
  }
}
