@if (trips.length) {
    <div class="top-itineraries-homepage-grid">
        <h2 class="title">Popular itineraries</h2>
        <div class="itineraries-container">
            <div class="itineraries-row">
                <div (click)="openTrip(0)" class="trip" title="{{trips[0].name}}"
                     [style.background-image]="'url(' + trips[0].image_url + ')'">
                    <a class="trip-link" [href]="trips[0].url" (click)="ignoreLink($event)">
                        <div class="trip-name">{{ trips[0].name }}</div>
                    </a>
                </div>
                <div (click)="openTrip(1)" class="trip" title="{{trips[1].name}}"
                     [style.background-image]="'url(' + trips[1].image_url + ')'">
                    <a class="trip-link" [href]="trips[1].url" (click)="ignoreLink($event)">
                        <div class="trip-name">{{ trips[1].name }}</div>
                    </a>
                </div>
                <div (click)="openTrip(2)" class="trip" title="{{trips[2].name}}"
                     [style.background-image]="'url(' + trips[2].image_url + ')'">
                    <a class="trip-link" [href]="trips[2].url" (click)="ignoreLink($event)">
                        <div class="trip-name">{{ trips[2].name }}</div>
                    </a>
                </div>
                <div (click)="openTrip(3)" class="trip" title="{{trips[3].name}}"
                     [style.background-image]="'url(' + trips[3].image_url + ')'">
                    <a class="trip-link" [href]="trips[3].url" (click)="ignoreLink($event)">
                        <div class="trip-name">{{ trips[3].name }}</div>
                    </a>
                </div>
            </div>
            <div class="itineraries-row">
                <div class="trip trip--text-only">
                    <div class="boxed-rows">
                        <span>Over 3,000</span>
                        <span>popular itineraries</span>
                    </div>
                </div>
                <div (click)="openTrip(4)" class="trip" title="{{trips[4].name}}"
                     [style.background-image]="'url(' + trips[4].image_url + ')'">
                    <a class="trip-link" [href]="trips[4].url" (click)="ignoreLink($event)">
                        <div class="trip-name">{{ trips[4].name }}</div>
                    </a>
                </div>
                <div (click)="openTrip(5)" class="trip" title="{{trips[5].name}}"
                     [style.background-image]="'url(' + trips[5].image_url + ')'">
                    <a class="trip-link" [href]="trips[5].url" (click)="ignoreLink($event)">
                        <div class="trip-name">{{ trips[5].name }}</div>
                    </a>
                </div>
                <div (click)="openTrip(6)" class="trip" title="{{trips[6].name}}"
                     [style.background-image]="'url(' + trips[6].image_url + ')'">
                    <a class="trip-link" [href]="trips[6].url" (click)="ignoreLink($event)">
                        <div class="trip-name">{{ trips[6].name }}</div>
                    </a>
                </div>
            </div>
            <div class="itineraries-row">
                <div (click)="openTrip(7)" class="trip" title="{{trips[7].name}}"
                     [style.background-image]="'url(' + trips[7].image_url + ')'">
                    <a class="trip-link" [href]="trips[7].url" (click)="ignoreLink($event)">
                        <div class="trip-name">{{ trips[7].name }}</div>
                    </a>
                </div>
                <div (click)="openTrip(8)" class="trip" title="{{trips[8].name}}"
                     [style.background-image]="'url(' + trips[8].image_url + ')'">
                    <a class="trip-link" [href]="trips[8].url" (click)="ignoreLink($event)">
                        <div class="trip-name">{{ trips[8].name }}</div>
                    </a>
                </div>
                <div (click)="openTrip(9)" class="trip" title="{{trips[9].name}}"
                     [style.background-image]="'url(' + trips[9].image_url + ')'">
                    <a class="trip-link" [href]="trips[9].url" (click)="ignoreLink($event)">
                        <div class="trip-name">{{ trips[9].name }}</div>
                    </a>
                </div>
                <div (click)="openTrip(10)" class="trip" title="{{trips[10].name}}"
                     [style.background-image]="'url(' + trips[10].image_url + ')'">
                    <a class="trip-link" [href]="trips[10].url" (click)="ignoreLink($event)">
                        <div class="trip-name">{{ trips[10].name }}</div>
                    </a>
                </div>
            </div>
        </div>
    </div>
}
