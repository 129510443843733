import { NgModule } from '@angular/core';
import { Location } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { ERoutes } from '../../core/enums/routes.enum';

import { DynamicResolverService } from 'src/app/core/settings/resolvers/dynamic-resolver.service';

import { HomeViewComponent } from 'src/app/modules/home/home-view/home-view.component';

const routes: Routes = [
  {
    path: ERoutes.EMPTY,
    component: HomeViewComponent,
    resolve: {
      data: DynamicResolverService,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [Location],
})
export class HomeRoutingModule {}
