import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';

import { Subject, takeUntil } from 'rxjs';

import { TripsService } from 'src/app/core/services/trips.service';
import { CloudinaryService } from 'src/app/core/services/cloudinary.service';

import { PopularTripDialogComponent } from 'src/app/shared/dialogs/popular-trip-dialog/popular-trip-dialog.component';

@Component({
  selector: 'app-top-itineraries-homepage',
  templateUrl: './top-itineraries-homepage.component.html',
  styleUrls: ['./top-itineraries-homepage.component.scss'],
  standalone: true,
})
export class TopItinerariesHomepageComponent implements OnInit, OnDestroy {
  trips: Array<any> = [];

  readonly #matDialog: MatDialog = inject(MatDialog);

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public router: Router,
    public tripsService: TripsService,
    private cloudinaryService: CloudinaryService,
  ) {}

  ngOnInit(): void {
    this.getTopTrips();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getTopTrips(): void {
    this.tripsService
      .getHomepageTopItineraries()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data && data.length) {
          this.trips = data;
          for (let i = 0; i < this.trips.length; i++) {
            const imageUrl = this.trips[i].image_url;
            if (imageUrl && !imageUrl.includes('cloudinary')) {
              this.trips[i].image_url =
                this.cloudinaryService.getCloudImage(imageUrl);
            }
          }
        }
      });
  }

  openTrip(index: number): void {
    const topTrip = this.trips[index];
    this.tripsService.getTripByUrlName(topTrip.url).subscribe((tripData) => {
      if (tripData) {
        const trip = { ...topTrip, tripData: tripData };
        this.#matDialog.open(PopularTripDialogComponent, {
          width: '1324px',
          maxWidth: '96vw',
          data: {
            trip: trip,
          },
        });
      }
    });
  }

  ignoreLink(ev: MouseEvent): boolean {
    ev.preventDefault();
    return false;
  }
}
