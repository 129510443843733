<div class="welcome-header" [style.background-image]="heroBgUrl">
    <div class="welcome-header__banner">
        <p>
            <strong>{{ 'HOME.MOBILE.P_BANNER_STRONG' | translate }}</strong> {{ 'HOME.MOBILE.P_BANNER_DEFAULT' | translate }}
        </p>
    </div>
    <div class="main-container">
        <div class="main-container--title">{{ 'MAIN.TITLE' | translate }}</div>
        <div class="main-container--sub-title">{{ 'MAIN.SUBTITLE' | translate }}</div>
        <app-trip-wizard [mode]="'standalone'"></app-trip-wizard>
        <div class="inner-content-box">
            <button class="hero__content--search btn-default btn-search" (click)="goToPlanningForm()">
                <mat-icon [svgIcon]="eSvgIcons.SEARCH"></mat-icon>
                <span>{{ 'HOME.MOBILE.WHERE_WOULD_YOU_LIKE_TO_GO' | translate }}</span>
            </button>
            <button class="hero__content--start btn-default btn-orange btn-mobile" (click)="goToPlanningForm()">
                {{ 'HOME.MOBILE.START_PLANING' | translate }}
            </button>
        </div>
    </div>
    <div class="slider-container f-row f-center">
        <div class="slider-container-info">
            <h2>{{ "MAIN.DESCRIPTION.TITLE" | translate }}</h2>
            <p>{{ "MAIN.DESCRIPTION.TEXT" | translate }}</p>
        </div>
        <div class="slider-container-swiper">
            <div class="slider-header">
                <h3 class="slider-header--title">
                    {{ "MAIN.DESCRIPTION.SLIDER.HEADER.TITLE" | translate }}
                </h3>
                @if (getActiveIndex(swiperRef.slideChange | async); as activeSlide) {
                    <div class="f-row f-center f-v-center">
                            <span class="slider-header--option" [class.active]="activeSlide === '0'">
                                {{ "MAIN.DESCRIPTION.SLIDER.HEADER.OPTION_1" | translate }}
                            </span>
                        <span class="slider-header--option" [class.active]="activeSlide === '1'">
                                {{ "MAIN.DESCRIPTION.SLIDER.HEADER.OPTION_2" | translate }}
                            </span>
                        <span class="slider-header--option" [class.active]="activeSlide === '2'">
                                {{ "MAIN.DESCRIPTION.SLIDER.HEADER.OPTION_3" | translate }}
                            </span>
                    </div>
                }
            </div>
            <div class="swiper" appSwiper #swiperRef="swiperRef" [swiperOptions]="swiperOptions">
                <div class="swiper-wrapper">
                    @for (slide of heroSlides; track slide.id; let idx = $index) {
                        <div class="swiper-slide">
                            <img class="swiper-slide__img"
                                 [src]="slide.imageUrl | safeUrl"
                                 width="560"
                                 height="270"
                                 alt="slide" />
                            <div class="swiper-slide__bg">
                                <h4>{{ slide.title }}</h4>
                                <p>{{ slide.description }}</p>
                            </div>
                        </div>
                    }
                </div>
                <div class="swiper-pagination"></div>
            </div>
        </div>
    </div>
</div>

<div class="content">
    <h2 class="content--title">With you every step of the way</h2>
    <div class="container f-row">
        <div class="container-dash"></div>
        <div class="container-col">
            <div class="container-col__pin f-row f-center">
                <div class="container-col--label">1</div>
            </div>
            <h3 class="container-col--title">Plan</h3>
            <h4 class="container-col--description">Three ways to start planning your perfect trip</h4>
            <button class="container-col--read-more" (click)="showMore($event, 1)">
                <span>{{'HOME.STEPS.READ_MORE' | translate}}</span>
                <mat-icon [svgIcon]="eSvgIcons.ARROW_RIGHT"></mat-icon>
            </button>
        </div>
        <div class="container-col">
            <div class="container-col__pin f-row f-center">
                <div class="container-col--label">2</div>
            </div>
            <h3 class="container-col--title">Optimize</h3>
            <h4 class="container-col--description">Optimize your trip in a click of a button</h4>
            <button class="container-col--read-more" (click)="showMore($event, 2)">
                <span>{{'HOME.STEPS.READ_MORE' | translate}}</span>
                <mat-icon [svgIcon]="eSvgIcons.ARROW_RIGHT"></mat-icon>
            </button>
        </div>
        <div class="container-col">
            <div class="container-col__pin f-row f-center">
                <div class="container-col--label">3</div>
            </div>
            <h3 class="container-col--title">Book</h3>
            <h4 class="container-col--description">
                Easily book your flights, accommodations, transportation and
                activities with our leading travel partners
            </h4>
            <button class="container-col--read-more" (click)="showMore($event, 3)">
                <span>{{'HOME.STEPS.READ_MORE' | translate}}</span>
                <mat-icon [svgIcon]="eSvgIcons.ARROW_RIGHT"></mat-icon>
            </button>
        </div>
        <div class="container-col">
            <div class="container-col__pin f-row f-center">
                <div class="container-col--label">4</div>
            </div>
            <h3 class="container-col--title">RoutePerfect perks</h3>
            <h4 class="container-col--description">
                RoutePerfect members enrich their trip with travel perks via our members’ app
            </h4>
            <button class="container-col--read-more" (click)="showMore($event, 4)">
                <span>{{'HOME.STEPS.READ_MORE' | translate}}</span>
                <mat-icon [svgIcon]="eSvgIcons.ARROW_RIGHT"></mat-icon>
            </button>
        </div>
    </div>
</div>

<div class="content content--extra">
    <div class="container f-row">
        <div class="container-col">
            <div class="border-box">
                <h4 class="container-col--title">Classic itinerary</h4>
                <p class="container-col--description">Simply enter your destination/s and travel dates to generate a
                    classic itinerary, enjoyed by thousands of travelers.
                    Then fine-tune your itinerary until it's tailored to perfection
                </p>
            </div>
            <div class="border-box">
                <h4 class="container-col--title">AI & travel expertise</h4>
                <p class="container-col--description">Chat with AI to describe your dream trip and your preferences.
                    RoutePerfect’s crowdsourced database and travel expertise are available to suggest new travel ideas
                    and optimize your itinerary
                </p>
            </div>
            <div class="border-box">
                <h4 class="container-col--title">Popular itineraries</h4>
                <p class="container-col--description">Get inspired by popular itineraries enjoyed by thousands of
                    travelers including special themed trips - art, culinary, music and other interests.
                    Then fine-tune your itinerary until it's tailored to perfection
                </p>
            </div>
        </div>
        <div class="container-col">
            <div class="border-box">
                <h4 class="container-col--title">RoutePerfect Optimizer</h4>
                <p class="container-col--description">RoutePerfect’s crowdsourced database and travel expertise are
                    available to optimize your AI itinerary.
                    <br>
                    Recommendations and suggest new travel ideas.
                </p>
            </div>
            <div class="border-box">
                <h4 class="container-col--title">Your Preferences</h4>
                <p class="container-col--description">You can refine the route according to your personal preferences.
                    More beaches? Great food? Nature? Art? Simply use the preferences slider and see a map of your
                    refined route in an instant</p>
                <img style="max-height: 575px" [src]="preferencesImgUrl | safeUrl" width="284" height="575" loading="lazy" alt="trip-config">
            </div>
        </div>
        <div class="container-col">
            <div class="border-box">
                <h4 class="container-col--title">Accommodations</h4>
                <p class="container-col--description">Book your accommodations with our partner Booking.com to become a
                    RoutePerfect member
                </p>
                <a href="https://www.booking.com/index.html?aid=7947415" target="_blank">
                    <img [src]="partnersLogosUrl[0] | safeUrl" width="180" height="30" loading="lazy" alt="booking">
                </a>
            </div>
            <div class="border-box">
                <h4 class="container-col--title">City activities</h4>
                <p class="container-col--description">View and book all landmarks and activities in every city you
                    visit</p>
                <a href="https://www.viator.com/?pid=P00076405&mcid=42383&medium=link&campaign=general" target="_blank">
                    <img [src]="partnersLogosUrl[1] | safeUrl" width="115" height="50" loading="lazy" alt="viator">
                </a>
            </div>
            <div class="border-box">
                <h4 class="container-col--title">Car rental</h4>
                <p class="container-col--description">
                    Book your car rental with our leading global partners
                </p>
                <div class="f-row f-v-center f-between">
                    @for (partnerUrl of carRentalPartners; track $index) {
                        <img [src]="partnerUrl | safeUrl" width="80" height="24" loading="lazy" alt="partner">
                    }
                </div>
            </div>
            <div class="border-box">
                <h4 class="container-col--title">Flights</h4>
                <p class="container-col--description">Book your flights with a quick click through to Kiwi.com and fly
                    for less</p>
                <a href="https://www.kiwi.com/deep?affilid=routeperfectltdrouteperfectdeeplink&currency=USD&departure=anytime&lang=en&return=anytime&returnFromDifferentAirport=false&returnToDifferentAirport=false"
                   target="_blank">
                    <img [src]="partnersLogosUrl[2] | safeUrl" width="220" height="48" loading="lazy" alt="kiwi">
                </a>
            </div>

        </div>
        <div class="container-col">
            <div class="border-box">
                <h4 class="container-col--title">Members’ app</h4>
                <p class="container-col--description">RoutePerfect members can enhance their trip on the go, with our
                    app</p>
            </div>
            <div class="border-box">
                <h4 class="container-col--title">‘En-Route’</h4>
                <p class="container-col--description">Discover sights along the way with our ‘En-Route’ explorer, making
                    your drive an amazing experience
                </p>
            </div>
            <div class="border-box">
                <h4 class="container-col--title">Occasions & events</h4>
                <p class="container-col--description">View all occasions and events before departure. Stay up-to-date on
                    the go, with our members’ app - see all the local last-minute events
                </p>
            </div>
            <div class="border-box">
                <h4 class="container-col--title">Organizer</h4>
                <p class="container-col--description">Keep all your travel information handy in the app's Organizer,
                    automatically updated when you book through us
                </p>
            </div>
            <div class="border-box">
                <h4 class="container-col--title">Audio city guide </h4>
                <p class="container-col--description">Get a free city voice guided tour for every hotel booked through
                    us - learn about the city at your own pace
                </p>
                <img [src]="partnersLogosUrl[3] | safeUrl" width="200" height="40" loading="lazy" alt="voice">
            </div>
        </div>
    </div>
</div>

<app-testimonials></app-testimonials>
<app-top-itineraries-homepage></app-top-itineraries-homepage>

<div class="start-planning-box">
    <app-start-planing-mobile></app-start-planing-mobile>
</div>
