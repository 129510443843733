<div class="dialog">
  <button class="dialog__close-btn" (click)="dialogRef.close()">
    <mat-icon [svgIcon]="eSvgIcons.CLOSE"></mat-icon>
  </button>

  <div class="dialog__content">
    <div class="way">
      <div class="way__header">
        <div class="way__step">
          <div class="way__step--count">
            {{currentStep}}
          </div>
          <h3>{{('HOME.STEPS.STEP_' + currentStep + '.TITLE') | translate}}</h3>
        </div>
      </div>
      <div class="way__content">
        @for (step of dataSteps; track step.step) {
          @if(step.step === currentStep) {
            @for(tip of step.content; track tip.title) {
              <div class="way__tip">
                <h4>{{tip.title | translate}}</h4>

                <div class="way__tip--paragraphs">
                  @for (paragraph of tip.paragraphs; track paragraph) {
                    @if(paragraph) {
                      <p>{{paragraph | translate}}</p>
                    }
                  }
                </div>

                <div class="way__tip--images">
                  @for (image of tip.imageUrls; track $index) {
                    @if(image) {
                      @if (image.affiliateLinkUrl) {
                        <a [href]="image.affiliateLinkUrl | safeUrl" target="_blank">
                          <img [src]="image.imageUrl | safeUrl" alt="step-image">
                        </a>
                      } @else {
                        <figure>
                          <img [src]="image.imageUrl | safeUrl" alt="step-image">
                        </figure>
                      }
                    }
                  }
                </div>
              </div>
            }
          }
        }
      </div>
      <div class="way__footer">
        @for (step of dataSteps; track step.step) {
          <button class="way__page"
                  [class.active]="step.step === currentStep"
                  (click)="setStep(step.step)"
          ></button>
        }
      </div>
    </div>
  </div>
</div>
